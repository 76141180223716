// gatsby-browser.js
import "./src/styles/global.css"

export const onRouteUpdate = ({ location }) => {
    // 페이지 이동 후 스크롤할 ID가 있는지 확인
    if (location.state && location.state.scrollToId) {
        // ID를 사용하여 요소를 찾습니다.
        const element = document.getElementById(location.state.scrollToId);

        // 요소가 존재하면 그 위치로 부드럽게 스크롤합니다.
        if (element) {
            window.scrollTo({
                top: element.offsetTop,
                behavior: 'smooth',
            });
        }
    }
};
